import { make } from "vuex-pathify";
import {
  PAGE_TYPE,
  WORKSHEET_MAPPING,
  WORKSHEET_TAB_MAPPING,
} from "../constants/commonConstants";
import {
  getCampaignDates,
  getCampaignOptions,
  getMediaOptions,
  getFilterOptions,
} from "./storeUtils";

const state = {
  filterOptions: {},
  selectedAdvertiser: "",
  selectedClient: "",
  showAdvertiserDropdown: false,
  isCampaignLoading: false,
  currentSelectedPageTab: PAGE_TYPE.HOME,
  selectedDashboardOption: "",
  conversionWindow: { value: "", isDisabled: true },
  selectedDateRange: {
    startDate: new Date(),
    endDate: new Date(),
  },
  isDashboardRefreshed: false,
  myReportsData: {
    reports: [],
  },
  clientList: {},
  selectedLiveboardTab: null,
  advertiserLoading: false,
  advertiserSolutionMapping: {},
  idSolutionMapping: {},
  solutionUiTabMapping: {},
  clientWhiteLabelMapping: {},
  availableWorksheets: {},
  disableAdvertiserDropdown: false,
  selectedWorksheetOption: null,
};
const getters = {
  campaignOptions: (state) => {
    return getCampaignOptions(getFilterOptions(state));
  },
  mediaTypeOptions: (state) => {
    return getMediaOptions(getFilterOptions(state));
  },
  selectedCampaigns: (state) => {
    return getCampaignOptions(getFilterOptions(state), true);
  },
  selectedConversion: (state) => {
    const { conversionWindow } = state;
    return conversionWindow;
  },
  selectedMediaType: (state) => {
    return getMediaOptions(getFilterOptions(state), true);
  },
  selectedCampaignDates: (state) => {
    return getCampaignDates(getFilterOptions(state), true);
  },
  getSelectedWorksheets: (state) => {
    const { selectedDashboardOption, selectedLiveboardTab } = state;
    const { searchDataUiSelection = "" } = selectedLiveboardTab || {};
    let selectedWorksheets = WORKSHEET_MAPPING[selectedDashboardOption] || [];
    if (searchDataUiSelection) {
      if (WORKSHEET_TAB_MAPPING[searchDataUiSelection]) {
        selectedWorksheets = [WORKSHEET_TAB_MAPPING[searchDataUiSelection]];
      }
    }
    return selectedWorksheets;
  },
};
const actions = {
  setClientList: ({ commit }, payload) => {
    commit("SET_CLIENT_LIST", payload);
  },
  setConfigMapping: ({ commit }, payload) => {
    commit("SET_CONFIG_MAPPING", payload);
  },
  setSelectedClient: ({ commit }, payload) => {
    commit("SET_SELECTED_CLIENT", payload);
  },
  setAdvertiserLoading: ({ commit }, payload) => {
    commit("SET_ADVERTISER_LOADING", payload);
  },
  setFilterOptions: ({ commit }, payload) => {
    commit("SET_FILTER_OPTIONS", payload);
  },
  setSelectedAdvertiser: ({ commit }, payload) => {
    commit("SET_SELECTED_ADVERTISER", payload);
  },
  setShowAdvertiserDropdown: ({ commit }, payload) => {
    commit("SET_SHOW_ADVERTISER_DROPDOWN", payload);
  },
  setCurrentSelectedPageTab: ({ commit }, payload) => {
    commit("SET_CURRENT_SELECTED_PAGE_TAB", payload);
  },
  setCampaignLoading: ({ commit }, payload) => {
    commit("SET_CAMPAIGN_LOADING", payload);
  },
  setSelectedDateRange: ({ commit }, payload) => {
    commit("SET_SELECTED_DATE_RANGE", payload);
  },
  setSelectedDashboardOption: ({ commit }, payload) => {
    commit("SET_SELECTED_DASHBOARD_OPTION", payload);
  },
  setConversionWindow: ({ commit }, payload) => {
    commit("SET_CONVERSION_WINDOW", payload);
  },
  toggleIsDashboardRefreshed: ({ commit }, payload) => {
    commit("TOGGLE_IS_DASHBOARD_REFRESHED", payload);
  },
  setReports: ({ commit }, payload) => {
    commit("SET_REPORTS", payload);
  },
  setSelectedLiveboardTab: ({ commit }, payload) => {
    commit("SET_SELECTED_LIVEBOARD_TAB", payload);
  },
  setAvailableWorksheets: ({ commit }, payload) => {
    commit("SET_AVAILABLE_WORKSHEETS", payload);
  },
  setDisableAdvertiserDropdown: ({ commit }, payload) => {
    commit("SET_DISABLE_ADVERTISER_DROPDOWN", payload);
  },
  setSelectedWorksheetOption: ({ commit }, payload) => {
    commit("SET_SELECTED_WORKSHEET_OPTION", payload);
  },
};
const mutations = {
  ...make.mutations(state),
  SET_CLIENT_LIST: (state, payload) => {
    state.clientList = payload;
  },
  SET_SELECTED_CLIENT: (state, payload) => {
    state.selectedClient = payload;
  },
  SET_ADVERTISER_LOADING: (state, payload) => {
    state.advertiserLoading = payload;
  },
  SET_FILTER_OPTIONS: (state, payload) => {
    state.filterOptions = payload;
  },
  SET_SELECTED_ADVERTISER: (state, payload) => {
    state.selectedAdvertiser = payload;
  },
  SET_SHOW_ADVERTISER_DROPDOWN: (state, payload) => {
    state.showAdvertiserDropdown = payload;
  },
  SET_CURRENT_SELECTED_PAGE_TAB: (state, payload) => {
    state.currentSelectedPageTab = payload;
  },
  SET_CAMPAIGN_LOADING: (state, payload) => {
    state.isCampaignLoading = payload;
  },
  SET_SELECTED_DASHBOARD_OPTION: (state, payload) => {
    state.selectedDashboardOption = payload;
  },
  SET_CONVERSION_WINDOW: (state, payload) => {
    state.conversionWindow = payload;
  },
  SET_SELECTED_DATE_RANGE: (state, payload) => {
    state.selectedDateRange = payload;
  },
  TOGGLE_IS_DASHBOARD_REFRESHED: (state, payload) => {
    state.isDashboardRefreshed = payload;
  },
  SET_REPORTS: (state, payload) => {
    state.myReportsData.reports = payload;
  },
  SET_SELECTED_LIVEBOARD_TAB: (state, payload) => {
    state.selectedLiveboardTab = payload;
  },
  SET_AVAILABLE_WORKSHEETS: (state, payload) => {
    state.availableWorksheets = payload;
  },
  SET_CONFIG_MAPPING: (state, payload) => {
    state.advertiserSolutionMapping = payload.advertiserSolutionMapping;
    state.idSolutionMapping = payload.idSolutionMapping;
    state.solutionUiTabMapping = payload.solutionUiTabMapping;
    state.clientWhiteLabelMapping = payload.clientWhiteLabelMapping;
  },
  SET_DISABLE_ADVERTISER_DROPDOWN: (state, payload) => {
    state.disableAdvertiserDropdown = payload;
  },
  SET_SELECTED_WORKSHEET_OPTION: (state, payload) => {
    state.selectedWorksheetOption = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
