import { get } from "vuex-pathify";
import {
  CONVERSION_WINDOW_CONFIG,
  DASHBOARD_PANEL_LIST,
  WORKSHEET_MAPPING,
} from "../constants/commonConstants";
import { getCampaigns } from "../services/searchApiService";
import { getCampaignDates, getMediaOptions } from "../store/storeUtils";

const computed = {
  advertiserSolutionMapping: get("common/advertiserSolutionMapping"),
  idSolutionMapping: get("common/idSolutionMapping"),
  selectedDashboardOption: get("common/selectedDashboardOption"),
  solutionUiTabMapping: get("common/solutionUiTabMapping"),
};
const getConsolidatedCampaigns = async (advertiser, worksheets = []) => {
  let sheetData = {};
  for (let i = 0; i < worksheets.length; i++) {
    const { data = [] } = await getCampaigns(advertiser, worksheets[i]);
    sheetData[worksheets[i]] = [...data.map((d) => [...d, worksheets[i]])];
  }
  return { dataObj: sheetData };
};
const methods = {
  getSelectedCampaignDates(filterObject, compareWithToday = false) {
    return getCampaignDates(filterObject, compareWithToday);
  },
  getTiles(client, advertiser) {
    const enableList = [];
    const disableList = [];
    let solutionIds = [];
    if (this.advertiserSolutionMapping[client]) {
      const clientObj = this.advertiserSolutionMapping[client];
      if (Object.keys(clientObj)?.includes("*")) {
        solutionIds = clientObj["*"].map((id) =>
          this.idSolutionMapping[id] ? this.idSolutionMapping[id] : ""
        );
      } else if (clientObj[advertiser]) {
        solutionIds = clientObj[advertiser].map((id) =>
          this.idSolutionMapping[id] ? this.idSolutionMapping[id] : ""
        );
      }
    }
    DASHBOARD_PANEL_LIST.forEach((dashboard) => {
      if (!solutionIds.includes(dashboard.id)) {
        dashboard.disabled = true;

        if (this.solutionUiTabMapping[dashboard.id]) {
          if (this.solutionUiTabMapping[dashboard?.id]?.[0]?.description) {
            dashboard.description =
              this.solutionUiTabMapping[dashboard?.id]?.[0].description;
          }
          disableList.push(dashboard);
        }
      } else {
        dashboard.disabled = false;
        enableList.push(dashboard);
      }
    });
    enableList.sort((a, b) => a.tagText.localeCompare(b.tagText));
    disableList.sort((a, b) => a.tagText.localeCompare(b.tagText));
    return { enableList, disableList };
  },
  getFilterOptions(dataList) {
    const filterObject = {};
    if (dataList?.length > 0) {
      const sortedDataList = dataList?.sort((a, b) => {
        if (b[3] === a[3]) {
          return b[1] - a[1];
        }
        return b[3] - a[3];
      });
      let order = 1;
      sortedDataList?.forEach((dt) => {
        dt.shift();
        const [campaign, mediaType, startDate, endDate, worksheetId] = dt;
        if (filterObject[campaign]) {
          filterObject[campaign].worksheetId = worksheetId;
          if (filterObject[campaign]?.mediaType) {
            const mediaTypeIndex = filterObject[campaign]?.mediaType?.findIndex(
              (f) => f?.name === mediaType
            );
            if (mediaTypeIndex >= 0) {
              const { dates } =
                filterObject[campaign]?.mediaType[mediaTypeIndex];
              if (
                !dates.find(
                  (d) => d.startDate === startDate && d.endDate === endDate
                )
              ) {
                filterObject[campaign]?.mediaType[mediaTypeIndex].dates.push({
                  startDate,
                  endDate,
                });
              }
            } else {
              filterObject[campaign]?.mediaType.push({
                name: mediaType,
                selected: true,
                dates: [{ startDate, endDate }],
              });
            }
          } else {
            filterObject[campaign] = {
              ...filterObject[campaign],
              mediaType: [
                {
                  name: mediaType,
                  selected: true,
                  dates: [{ startDate, endDate }],
                },
              ],
            };
          }
        } else {
          filterObject[campaign] = {
            order,
            selected: true,
            mediaType: [
              {
                name: mediaType,
                selected: true,
                dates: [{ startDate, endDate }],
              },
            ],
            worksheetId,
          };
          order = order + 1;
        }
      });
    }
    return filterObject;
  },
  setConversionWindow(liveBoardTab, selectedDashboardOption) {
    if (liveBoardTab?.uiTabName) {
      const selectedConversionWindow =
        CONVERSION_WINDOW_CONFIG[
          `${selectedDashboardOption}-${liveBoardTab?.uiTabName}`
        ];
      this.$store.dispatch(
        "common/setConversionWindow",
        selectedConversionWindow
          ? selectedConversionWindow
          : {
              value: "",
              isDisabled: true,
            }
      );
    } else {
      this.$store.dispatch("common/setConversionWindow", {
        value: "",
        isDisabled: true,
      });
    }
  },
  async setCampaignAndMediaOptions(
    advertiser,
    refreshDashbord = false,
    client
  ) {
    this.$store.dispatch("common/setCampaignLoading", true);
    this.$store.dispatch("common/setFilterOptions", {});

    // const campaignData = await getCampaigns({
    //     advertiser,
    // });

    const advertiserObj = this?.advertiserSolutionMapping[client] || {};
    const solutionList =
      client === "MNI Motto"
        ? advertiserObj["*"]
        : advertiserObj[advertiser] || [];

    const worksheets = [];

    if (solutionList.length > 0) {
      solutionList.forEach((listId) => {
        if (this.idSolutionMapping[listId]) {
          if (WORKSHEET_MAPPING[this.idSolutionMapping[listId]].length > 0)
            worksheets.push(
              ...WORKSHEET_MAPPING[this.idSolutionMapping[listId]]
            );
        }
      });
    }

    // const clientObj = this.advertiserSolutionMapping[client];
    const campaignData = await getConsolidatedCampaigns(advertiser, [
      ...new Set(worksheets),
    ]);

    const { dataObj } = campaignData;
    const filterObject = {};
    Object.keys(dataObj).forEach((dataKey) => {
      const tempFilterObj = this.getFilterOptions(dataObj[dataKey]);
      const selectedCampaignDates = this.getSelectedCampaignDates(
        tempFilterObj,
        true
      );
      const startDate = selectedCampaignDates?.startDate?.getTime() / 1000;
      const mediaOptions = getMediaOptions(tempFilterObj);
      if (mediaOptions.length > 0) {
        const optionKeys = Object.keys(tempFilterObj);
        optionKeys.forEach((optionKey) => {
          const { mediaType } = tempFilterObj[optionKey];
          if (mediaType?.length > 0) {
            let isLatestStartDate = false;
            mediaType.forEach((md) => {
              if (md?.dates?.length > 0) {
                md?.dates?.forEach((date) => {
                  if (date.startDate === startDate) {
                    isLatestStartDate = true;
                  }
                });
              }
              if (isLatestStartDate) {
                mediaType.selected = true;
              } else {
                mediaType.selected = false;
              }
            });
            if (isLatestStartDate) {
              tempFilterObj[optionKey].selected = true;
            } else {
              tempFilterObj[optionKey].selected = false;
            }
          }
        });
      }
      filterObject[dataKey] = tempFilterObj;
    });
    const selectedWorksheet =
      WORKSHEET_MAPPING[this.selectedDashboardOption] || [];
    if (selectedWorksheet?.length > 0) {
      let requiredCampObj = {};
      selectedWorksheet.map((worksheet) => {
        requiredCampObj = {
          ...requiredCampObj,
          ...(filterObject[worksheet] || {}),
        };
      });
      const updatedSelectedDates =
        this.getSelectedCampaignDates(requiredCampObj);
      this.$store.dispatch("common/setSelectedDateRange", updatedSelectedDates);
    }
    this.$store.dispatch("common/setFilterOptions", filterObject);
    if (refreshDashbord) {
      this.$store.dispatch("common/toggleIsDashboardRefreshed", true);
    }
    this.$store.dispatch("common/setCampaignLoading", false);
  },
};

export default {
  methods,
  computed,
};
