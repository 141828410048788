import {
  WORKSHEET_MAPPING,
  WORKSHEET_TAB_MAPPING,
} from "../constants/commonConstants";

export const getMediaOptions = (filterOptions = {}, checkSelected = false) => {
  const campaignKeys = Object.keys(filterOptions);
  const mediaTypeOptions = [];
  campaignKeys.forEach((campaign) => {
    const campaignObject = filterOptions[campaign];
    if (campaignObject?.selected) {
      const { mediaType } = campaignObject;
      mediaType.forEach((m) => {
        const mediaOptionIndex = mediaTypeOptions.findIndex(
          (media) => media?.name === m?.name
        );
        if (mediaOptionIndex !== -1) {
          const { selected = false } = mediaType[mediaOptionIndex] || {};
          if (checkSelected) {
            if (selected) {
              mediaTypeOptions[mediaOptionIndex] = {
                ...mediaTypeOptions[mediaOptionIndex],
                dates: [
                  ...mediaTypeOptions[mediaOptionIndex].dates,
                  ...m.dates,
                ],
              };
            }
          } else {
            mediaTypeOptions[mediaOptionIndex] = {
              ...mediaTypeOptions[mediaOptionIndex],
              dates: [...mediaTypeOptions[mediaOptionIndex].dates, ...m.dates],
            };
          }
        } else {
          if (checkSelected) {
            if (m.selected) {
              mediaTypeOptions.push(m);
            }
          } else {
            mediaTypeOptions.push(m);
          }
        }
      });
    }
  });
  return mediaTypeOptions;
};

export const getCampaignOptions = (
  filterOptions = {},
  checkSelected = false
) => {
  const campaignOptions = [];
  const campaignKeys = Object.keys(filterOptions);
  campaignKeys.forEach((key) => {
    if (checkSelected) {
      if (filterOptions[key].selected) {
        campaignOptions.push({
          name: key,
          order: filterOptions[key].order,
          selected: filterOptions[key].selected,
        });
      }
    } else {
      campaignOptions.push({
        name: key,
        order: filterOptions[key].order,
        selected: filterOptions[key].selected,
      });
    }
  });
  if (campaignOptions.length > 0) {
    return campaignOptions?.sort((a, b) => a.order - b.order);
  }
  return campaignOptions;
};

export const getCampaignDates = (
  filterOptions = {},
  compareWithToday = false
) => {
  const campaignKeys = Object?.keys(filterOptions) || [];
  const campaignStartDate = [];
  // const campaignEnddDate = [];
  campaignKeys.forEach((campaign) => {
    const campaignObject = filterOptions[campaign];
    if (campaignObject?.selected) {
      const { mediaType } = campaignObject;
      mediaType.forEach((m) => {
        if (m.selected) {
          m.dates.forEach((date) => {
            campaignStartDate.push(date.startDate);
            // campaignEnddDate.push(date.endDate);
          });
        }
      });
    }
  });
  const todayDate = new Date();
  const todayEndOfDay = todayDate.setHours(23, 59, 59, 999) / 1000;
  campaignStartDate.push(todayEndOfDay);
  campaignStartDate.sort((a, b) => a - b);
  const todayDateIndex = campaignStartDate.findIndex(
    (ev) => ev === todayEndOfDay
  );
  let startDate = new Date();
  if (compareWithToday)
    if (todayDateIndex) {
      startDate = new Date(campaignStartDate[todayDateIndex - 1] * 1000);
    } else {
      startDate = new Date(campaignStartDate[todayDateIndex] * 1000);
    }
  else {
    startDate = new Date(campaignStartDate[0] * 1000);
  }
  const endDate = new Date(campaignStartDate[todayDateIndex] * 1000);
  return { startDate, endDate };
};

export const getFilterOptions = (state) => {
  const { filterOptions, selectedDashboardOption, selectedLiveboardTab } =
    state;
  const { searchDataUiSelection = "" } = selectedLiveboardTab || {};
  let selectedWorksheets = WORKSHEET_MAPPING[selectedDashboardOption] || [];
  if (searchDataUiSelection) {
    if (WORKSHEET_TAB_MAPPING[searchDataUiSelection]) {
      selectedWorksheets = [WORKSHEET_TAB_MAPPING[searchDataUiSelection]];
    }
  }
  let requiredCampObj = {};
  selectedWorksheets.map((worksheet) => {
    requiredCampObj = {
      ...requiredCampObj,
      ...(filterOptions[worksheet] || {}),
    };
  });
  return requiredCampObj;
};
