import { MONTHS } from "./constants/commonConstants";

export const getUrlParams = () => {
    let paramsMap = {};
    const url = window.location.href;
    const paramVars = url?.split("?")[1]?.split("&") || [];
    for (const param of paramVars) {
        const pair = param.split("=");
        paramsMap[pair[0].toLowerCase()] = decodeURIComponent(pair[1]);
    }
    return paramsMap;
};
export const delete_cookie = (name) => {
    document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const logoutUser = () => {
    delete_cookie("user_auth_token");
    window.location.reload();
};

export const dateFormatter = (date, type = "") => {
    if (date) {
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;
        let day = date.getDate();
        day = day < 10 ? `0${day}` : day;
        let postFix = "am";
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (hours >= 12 && minutes > 0) {
            postFix = "pm";
        }
        if (hours > 12) {
            hours -= 12;
        }
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        switch (type) {
            case "MM,DD-YYYY":
                return `${MONTHS[date.getMonth()]}, ${day} - ${year}`;
            case "YYYY-MM-DD":
                return `${year}-${month}-${day}`;
            case "DD/MM/YYYY":
                return `${day}/${month}/${year}`;

            case "YYYY-MM-DD HH:MM":
                return `${year}-${month}-${day} ${hours}:${minutes} ${postFix}`;
            default:
                return `${MONTHS[date.getMonth()]}, ${day} ${year}`;
        }
    }
    return "";
};
export const getUUID = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const resetLiveBoardDiv = () => {
    document.getElementById("embed").innerHTML = "";
    document.getElementById("embed").style.height = "unset";
};
