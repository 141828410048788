import { get } from "vuex-pathify";
import { ALL_WORKSHEETS, HIDE_ADVERTISERS } from "../constants/commonConstants";
import { getAdvertisers, getMyWorksheets } from "../services/searchApiService";
import clientData from "../store/data/client-solutions.json";
import {
	getAllSolutions,
	getClientSolutions,
} from "../services/solutionsService";
import { getCookie } from "../utils";

async function mounted() {
	const authCookie = getCookie("user_auth_token");
	if (authCookie) {
		await this.setAdvertisersData();
		await this.setAvailableWorksheets();
	}
}
const computed = {
	advertisers: get("common/advertisers"),
};
const getConsolidatedAdvertiser = async () => {
	let sheetData = [];
	let mvpClients = [];
	clientData["client_solutions_mapping"].forEach((record) => {
		mvpClients.push(record["Agency"]);
	});
	mvpClients = [...new Set(mvpClients)];
	for (let i = 0; i < ALL_WORKSHEETS.length; i++) {
		const { data = [] } = await getAdvertisers(ALL_WORKSHEETS[i], mvpClients);
		if (data?.length > 0) {
			sheetData = [...sheetData, ...data];
		}
	}
	return sheetData.filter((data) => !HIDE_ADVERTISERS.includes(data[0]));
};
const methods = {
	async setAdvertisersData() {
		this.$store.dispatch("common/setAdvertiserLoading", true);
		const sheetData = await getConsolidatedAdvertiser();
		const { data: clientSolutions = [] } = await getClientSolutions();
		const { data: allSolutions = [] } = await getAllSolutions();
		const idSolutionMapping = {};
		const solutionUiTabMapping = {};
		const advertiserSolutionMapping = {};
		const clientWhiteLabelMapping = {};
		allSolutions.forEach((data) => {
			idSolutionMapping[data[0]] = data[1];
			if (solutionUiTabMapping[data[1]]) {
				solutionUiTabMapping[data[1]].push({
					id: data[0],
					uiTabName: data[2],
					liveboardName: data[3],
					liveboardGuid: data[4],
					description: data[5],
					worksheets: data[8],
					searchDataUiSelection: data[9],
				});
			} else {
				solutionUiTabMapping[data[1]] = [
					{
						id: data[0],
						uiTabName: data[2],
						liveboardName: data[3],
						liveboardGuid: data[4],
						description: data[5],
						worksheets: data[8],
						searchDataUiSelection: data[9],
					},
				];
			}
		});

		clientSolutions.forEach((client) => {
			const [clientName, advertiser, solutionId, whiteLabel] = client;
			clientWhiteLabelMapping[clientName] = whiteLabel;
			if (advertiserSolutionMapping[clientName]) {
				if (advertiserSolutionMapping[clientName][advertiser]) {
					advertiserSolutionMapping[clientName][advertiser].push(solutionId);
				} else {
					advertiserSolutionMapping[clientName][advertiser] = [solutionId];
				}
			} else {
				advertiserSolutionMapping[clientName] = {
					[advertiser]: [solutionId],
				};
			}
		});
		this.$store.dispatch("common/setConfigMapping", {
			idSolutionMapping,
			solutionUiTabMapping,
			advertiserSolutionMapping,
			clientWhiteLabelMapping,
		});
		const clientList = {};
		sheetData.forEach((dt) => {
			if (clientList[dt[0]]) {
				if (!clientList[dt[0]].includes(dt[1])) {
					clientList[dt[0]].push(dt[1]);
				}
			} else {
				clientList[dt[0]] = [dt[1]];
			}
		});
		Object.keys(clientList).forEach((cl) => clientList[cl].sort());
		this.$store.dispatch("common/setClientList", clientList);
		const sortedClientList = Object.keys(clientList)?.sort();
		if (sortedClientList.length > 0) {
			this.$store.dispatch(
				"common/setSelectedAdvertiser",
				clientList[sortedClientList[0]][0]
			);
			this.$store.dispatch("common/setSelectedClient", sortedClientList[0]);
			const advertiser = clientList[sortedClientList[0]][0];
			this.setCampaignAndMediaOptions(advertiser, false, sortedClientList[0]);
		}
		this.$store.dispatch("common/setAdvertiserLoading", false);
	},

	async setAvailableWorksheets() {
		const { data: worksheets } = await getMyWorksheets();
		const { headers = [] } = worksheets;
		const reqData = {};
		headers?.forEach((h) => {
			if (h.type === "WORKSHEET") {
				reqData[h.name] = h.id;
			}
		});
		this.$store.dispatch("common/setAvailableWorksheets", reqData);
	},
};

export default {
	mounted,
	methods,
	computed,
};
