//import config from "../config";
export const PENDO_KEY = "d2e021ca-ebff-4bbe-4bf3-2e9bdee5380c";
export const PAGE_TYPE = {
  SOLUTIONS: "solutions",
  MY_REPORTS: "my-reports",
  SEARCH_DATA: "search-data",
  HOME: "home",
};

export const PAGE_TAB = {
  [PAGE_TYPE.HOME]: {
    path: PAGE_TYPE.HOME,
    name: "Home",
    className: "home-page",
  },
  [PAGE_TYPE.SOLUTIONS]: {
    path: PAGE_TYPE.SOLUTIONS,
    name: "Solutions",
    className: "solutions",
  },
  [PAGE_TYPE.MY_REPORTS]: {
    path: PAGE_TYPE.MY_REPORTS,
    name: "My Reports",
    className: "my_reports",
  },
  [PAGE_TYPE.SEARCH_DATA]: {
    path: PAGE_TYPE.SEARCH_DATA,
    name: "Search Data",
    className: "search_data",
  },
};

export const MVP_CLIENTS = [
  "AdStrategies",
  "Altered State",
  "Big Sea",
  "Jersey Mike's Subs",
  "MNI Motto",
  "Marketwake",
  "Mediamax",
  "Merlino Media",
  "Ron Foth",
  "We Are Guru",
];

// This is a temporary code (hack) to hide certian advertisers that we get in response
// because of bad data in Big-Query
export const HIDE_ADVERTISERS = ["Adstrategies"];

export const BASE_TS_URL = "https://digitalremedy.thoughtspot.cloud";
export const SEARCH_API_URL = "/callosum/v1/tspublic/v1/searchdata";
export const METADATA_API_URL = "/callosum/v1/tspublic/v1/metadata/list";
export const USER_API_URL = "/callosum/v1/tspublic/v1/user/updatepassword";

export const CLIENT_ADVERTISERS_COLUMNS = "[Client] [Advertiser]";
export const CAMPAIGN_COLUMNS =
  "[Advertiser] [Campaign] [Media Type] [Startdate].'detailed' [Enddate].'detailed'";

export const ALL_SOLUTIONS_COLUMNS =
  "[Id][Solution][UI Tab][Liveboard Name][Liveboard Guid][Image]";
export const CLIENT_SOLUTIONS_COLUMNS = "[Client][Advertiser][Solution Id]";

export const CAMPAIGN_WORKSHEET = "e3f23f5e-7513-4f24-8a8d-03517f148839";
export const ALL_SOLUTIONS_WORKSHEET = "572ef21c-986a-4912-9cb3-709738a75d94";
export const CLIENT_SOLUTIONS_WORKSHEET =
  "014b9dcb-dc87-43a8-b64f-02fca87e4d53";

// All underlying worksheets. Used to populate Agency-Advertiser dropdown.
export const ALL_WORKSHEETS = [
  "ccb2c445-112d-44f5-952d-d0a744781a76",
  "e3f23f5e-7513-4f24-8a8d-03517f148839",
];

export const DASHBOARD_PANEL_LIST = [
  {
    backgroundImageUrl: "drive_outcomes_dsp_pixel",
    tagText: "Multi-DSP Access",
    disabled: false,
    id: "Multi-DSP Access",
  },
  {
    backgroundImageUrl: "performance_image",
    tagText: "Premium CTV",
    disabled: false,
    id: "Premium CTV",
  },
  {
    backgroundImageUrl: "linear_insights_image",
    tagText: "TV Insights",
    disabled: false,
    id: "TV Insights",
  },

  {
    backgroundImageUrl: "youtube_tv",
    tagText: "YouTube TV",
    disabled: false,
    id: "YouTube TV",
  },
  {
    backgroundImageUrl: "search_social",
    tagText: "Search / Social",
    disabled: false,
    id: "Search / Social",
  },
];
export const WORKSHEET_TAB_MAPPING = {
  Search: "9fab1550-9c9a-4398-8fa9-bfd4c13f24e9",
  Social: "676208f9-5d98-485e-95a8-98a8eb5a72a1",
  YouTube: "4c6a82f5-8f69-4495-844c-7d734c3ad1a5",
};
export const POSSIBLE_WORSHEET_TABS = [
  "YouTube TV",
  "TV Insights",
  "Premium CTV",
  "Brand Insights",
  "Multi-DSP Access",
  "Search",
  "Social",
  "YouTube",
];
export const SEARCH_SOCIAL_SOLUTION_MAPPING = ["Search", "Social", "YouTube"];
export const WORKSHEET_MAPPING = {
  "Search / Social": [
    "9fab1550-9c9a-4398-8fa9-bfd4c13f24e9",
    "676208f9-5d98-485e-95a8-98a8eb5a72a1",
    "4c6a82f5-8f69-4495-844c-7d734c3ad1a5",
  ],
  "YouTube TV": ["09f3f70f-989e-42d5-9f9d-5c5be27f8207"],
  "TV Insights": ["ccb2c445-112d-44f5-952d-d0a744781a76"],
  "Premium CTV": ["e3f23f5e-7513-4f24-8a8d-03517f148839"],
  "Brand Insights": ["ccb2c445-112d-44f5-952d-d0a744781a76"],
  "Drive Outcomes (IP attribution)": [""],
  "Multi-DSP Access": ["ccb2c445-112d-44f5-952d-d0a744781a76"],
};
export const CONVERSION_WINDOW_CONFIG = {
  "Premium CTV-Conversions": { value: 31, isDisabled: false },
  "Premium CTV-Footfall": { value: 31, isDisabled: false },
  "Premium CTV-Reporting": { value: 31, isDisabled: false },
  "TV Insights-Conversions": { value: 31, isDisabled: false },
  "TV Insights-Cross-platform Measurement": { value: 31, isDisabled: false },
  "Action (DSP)-Conversions": { value: 31, isDisabled: true },
  "Action (DSP)-Reporting": { value: 31, isDisabled: true },
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const WEEK_DAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
export const TABS_ORDER_CONFIG = [
  "Delivery",
  "Conversions",
  "Search",
  "Social",
  "YouTube",
  "Reporting",
  "Footfall",
  "Competitive Analysis",
  "TV Measurement",
  "Cross-platform Measurement",
  "Planning",
  "Creative",
  "Channel",
];
export const TABS_CONFIG = {
  Delivery: {
    label: "Delivery",
    icon: "forwardArrowIcon",
  },
  Conversions: {
    label: "Conversions",
    icon: "barIcon",
  },
  Reporting: {
    label: "Reporting",
    icon: "shareIcon",
  },
  Footfall: {
    label: "Footfall",
    icon: "footfallIcon",
  },
  "Competitive Analysis": {
    label: "Competitive Analysis",
    icon: "projectGraphIcon",
  },
  "TV Measurement": {
    label: "TV Measurement",
    icon: "tvIcon",
  },
  "Cross-platform Measurement": {
    label: "Cross-platform Measurement",
    icon: "crossPlatformIcon",
  },
  Planning: {
    label: "Planning",
    icon: "planningIcon",
  },
  Search: {
    label: "Search",
    icon: "SearchTabIcon",
  },
  Social: {
    label: "Social",
    icon: "socialIcon",
  },
  YouTube: {
    label: "YouTube",
    icon: "youtubeIcon",
  },
  Creative: {
    label: "Creative",
    icon: "creativeIcon",
  },
  Channel: {
    label: "Channel",
    icon: "",
  },
};
